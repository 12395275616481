import * as zod from "zod";

export const SignInRequestSchema = zod.object({
    username: zod.string(),
    password: zod.string(),
    scope: zod.string(),
});
export type SignInRequestSchemaType = zod.infer<typeof SignInRequestSchema>;
export const SignInResponseSchema = zod.object({
    access_token: zod.string(),
    token_type: zod.string(),
});
export type SignInResponseSchemaType = zod.infer<typeof SignInResponseSchema>;

export const SignUpRequestSchema = zod.object({
    email: zod.string(),
    name: zod.string(),
    field_of_interest: zod.string(),
    password: zod.string(),
});
export type SignUpRequestSchemaType = zod.infer<typeof SignUpRequestSchema>;
export const SignUpResponseSchema = zod.object({
    email: zod.string(),
    name: zod.string(),
    field_of_interest: zod.string(),
    hashed_password: zod.string(),
    limit: zod.number(),
    request_count: zod.number(),
    registration_date: zod.string(),
});
export type SignUpResponseSchemaType = zod.infer<typeof SignUpResponseSchema>;

export const SegmentPipelineRequestShema = zod.object({
    link: zod.string(),
    n_chapters: zod.number(),
});
export type SegmentPipelineRequestShemaType = zod.infer<
    typeof SegmentPipelineRequestShema
>;
