import styled from "styled-components";
import checkIcon from "../../assets/check-icon.svg";
import selectArrow from "../../assets/select-arrow.svg";

export const ProgrammWrap = styled.section`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  .header{
      display: flex;
      background-color: ${({ theme }) => theme.colors.white};
      height: 65px;
      border-bottom: 1px solid ${({ theme }) => theme.colors.borderColor};
        @media screen and (max-width: 991px){
          flex-wrap: wrap;
          height: auto;
        }
      &-logo{
        height: 65px;
        display: flex;
        align-items: center;
        width: 252px;
        padding-left: 30px;
        box-sizing: border-box;
        @media screen and (max-width: 991px){
          flex: 1 ;
          width: auto;
          padding-left: 20px;
        }
        img{
          max-width: 127px;
        }
      }
      &-inner{
        flex: 1;
        border-left: 1px solid ${({ theme }) => theme.colors.borderColor};
        border-right: 1px solid ${({ theme }) => theme.colors.borderColor};
        display: flex;
        align-items: center;
        height: 65px;
        padding: 0 30px;
        box-sizing: border-box;
        @media screen and (max-width: 991px){
          order: 3;
          flex: 1;
          width: 100%;
          min-width: 100vw;
          border-top: 1px solid ${({ theme }) => theme.colors.borderColor};
          border-left: none;
          border-right: none;
          background-color: #fff;
          padding: 0 20px;
        }
        &-name{
          flex: 1;
          display: flex;
          align-items: center;
          font-weight: 500;
          font-size: 18px;
          line-height: 23px;
          color: ${({ theme }) => theme.colors.black};
        }
        button{
          @media screen and (max-width: 991px){
            width: 34px;
            height: 34px;
            span{
              display: none;
            }
            i{
              margin: 0;
            }
          }
        }
        &-actions{
          position: relative;
        }
      }
      &-actions{
        display: flex;
        align-items: center;
        height: 65px;
        padding: 0 30px;
        box-sizing: border-box;
        @media screen and (max-width: 991px){
          padding: 0 20px;
        }
        &-item{
          display: flex;
          align-items: center;
          justify-content: center;
          &:not(:first-child){
            margin-left: 30px;
            @media screen and (max-width: 991px){
              margin-left: 20px;
            }
          }
          &.hide-mobile{
            @media screen and (max-width: 991px){
              display: none;
            }
          }
          &.show-mobile{
            display: none;
            @media screen and (max-width: 991px){
              display: block;
            }
          }
        }
        &-button{
          display: flex;
          align-items: center;
          justify-content: center;
          position: relative;
          cursor: pointer;
          transition: opacity ease-in-out 0.1s, transform ease-in 0.1s;
          &:hover{
            opacity: 0.9;
          }
          &:active{
            transform: scale(0.94);
          }
          &-toggle{
            position: relative;
            width: 24px;
            height: 16px;
            span{
              position: absolute;
              left: 0;
              width: 100%;
              height: 2px;
              background-color: #739EF2;
              border-radius: 10px;
              transition: all ease-in 0.1s;
              &:nth-child(1){
                top: 0;
              }
              &:nth-child(2){
                top: 50%;
                transform: translate(0, -50%);
              }
              &:nth-child(3){
                bottom: 0;
              }
            }
            &.active{
              span{
                transition: all ease-in 0.1s;
                &:nth-child(1){
                  top: 50%;
                  transform: translate(0, -50%) rotate(-45deg);
                }
                &:nth-child(2){
                  opacity: 0;
                }
                &:nth-child(3){
                  top: 50%;
                  transform: translate(0, -50%) rotate(45deg);
                  bottom: inherit;
                }
              }
            }
          }
        }
        
      }
      
      &-user{
        display: flex;
        align-items: center;
        cursor: pointer;
        transition: opacity ease-in-out 0.1s;
        &:hover{
          opacity: 0.9;
        }
        &-photo{
          width: 45px;
          height: 45px;
          border-radius: 100%;
          overflow: hidden;
          margin-right: 10px;
          @media screen and (max-width: 991px){
            margin-right: 0;
          }
          img{
            width: 100%;
            height: 100%;
            object-fit: cover;
            display: block;
          }
        }
        &-name{
          flex: 1;
          font-weight: 500;
          font-size: 13px;
          line-height: 17px;
          color: ${({ theme }) => theme.colors.black};
          @media screen and (max-width: 991px){
            display: none;
          }
        }
      }
    }
  .content{
    flex: 1;
    display: flex;
    &-panel{
      display: flex;
      flex-direction: column;
      width: 252px;
      background-color: ${({ theme }) => theme.colors.white};
      @media screen and (max-width: 991px){
        position: fixed;
        width: 100vw;
        min-height: calc(100vh - 66px);
        top: 66px;
        display: flex;
        height: calc(100vh - 66px);
        z-index: 99;
        overflow-y: auto;
        visibility: hidden;
        opacity: 0;
        transition: opacity ease-out .2s, visibility ease-out .2s;
        &.active{
          opacity: 1;
          visibility: visible;  
        }
      }
      &-footer{
        margin-top: auto;
        padding: 20px;
        box-sizing: border-box;
        &-item{
          &:not(:last-child){
            margin-bottom: 30px;
          }
          button{
            width: 100%;
          }
        }
      }
    }
    &-chat{
      flex: 1;
      border-left: 1px solid ${({ theme }) => theme.colors.borderColor};
      display: flex;
      flex-direction: column;
      position: relative;
      &-filter{
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        background-color: #FBFBFD;
        display: flex;
        z-index: 2;
        @media screen and (max-width: 576px){
          display: none;
        }
        &.active{
          display: flex;
        }
        .filter-item{
          display: flex;
          align-items: center;
          padding: 16px 15px;
          &:first-child{
            padding-left: 30px;
          }
        }
        .filter-name{
          margin-right: 10px;
          color:  ${({ theme }) => theme.colors.black};
        }
        .filter-items{
          display: flex;
        }
        .filter-items-item{
          padding: 4px 10px;
          border-radius: 5px;
          text-transform: capitalize;
          &:not(:last-child){
            margin-right: 10px;
          }
          &.LOCATION{
            background: rgba(209, 72, 147, 0.15);
            color: #D14893;
          }
          
          &.PERSON{
            background: rgba(115, 158, 242, 0.2);
            color: #5C8BE8;
          }
          &.PHONE_NUMBER{
            background: rgba(24, 149, 104, 0.15);
            color: #189568;
          }
          &.EMAIL_ADDRESS{
            background: rgba(210, 101, 71, 0.15);
            color: #D26547;
          }
          &.ORGANIZATION{
            background: rgba(222, 127, 39, 0.15);
            color: #DE7F27;
          }
          &.IP_ADDRESS,
          &.URL,
          &.SSN,
          &.DATE_TIME{
            background: rgb(243 243 243);
            color: ${({ theme }) => theme.colors.black};
          }
        }
      }
      &-messages{
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        max-height: calc(100vh - 125px);
        overflow-y: auto;
        @media screen and (max-width: 991px){
          max-height: calc(100vh - 191px);
        }
        a{
          color: inherit;
          &:hover{
            text-decoration: none;
          }
        }
        .on-tag{
          text-decoration: underline;
        }
      }
      &-form{}
    }
  }
`;

export const ProgramForm = styled.form`
  display: flex;
  background-color: ${({ theme }) => theme.colors.white};
`;

export const ProgramInput = styled.input`
  flex: 1;
  height: 60px;
  border: none;
  outline: none;
  padding: 0 30px;
  box-sizing: border-box;
  font-size: 14px;
  line-height: 140%;
  color: ${({ theme }) => theme.colors.black};
  @media screen and (max-width: 991px){
    padding: 0 20px;
  }
  &::placeholder{
    color: #A4A4A4;
    transition: color ease-in 0.05s;
  }
  &:-ms-input-placeholder{
    color: #A4A4A4;
    transition: color ease-in 0.05s;
  }
  &::-ms-input-placeholder{
    color: #A4A4A4;
    transition: color ease-in 0.05s;
  }
  &:focus{
    &::placeholder{
      //color: transparent;
    }
    &:-ms-input-placeholder{
      //color: transparent;
    }
    &::-ms-input-placeholder{
      //color: transparent;
    }
  }
`;

export const ProgramButton = styled.button`
  border: none;
  height: 60px;
  width: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  cursor: pointer;
  outline: none;
  transition: opacity ease-in 0.1s, transform ease-in 0.1s;
  path{
    transition: fill ease-in 0.1s, fill-opacity ease-in 0.1s;
  }
  &:hover{
    path{
      fill: ${({ theme }) => theme.colors.blue};
      fill-opacity: 1;
    }
  }
  &:active{
    transform: scale(0.99);
  }
`;

export const ProgramChat = styled.div`
  padding: 20px 30px;
  @media screen and (max-width: 991px){
    padding: 20px;
  }
    .message{
      text-align: left;
      display: inline-flex;
      max-width: 1500px;
      position: relative;
      @media screen and (max-width: 991px){
        flex-direction: column;
      }
      &:nth-child(1){
        margin-top: auto;
      }
      &:not(:last-child){
        margin-bottom: 20px;
      }
      
      &.user{
        @media screen and (max-width: 991px){
          margin-left: auto;
          align-items: flex-end;
          text-align: right;
          .message-date{
            left: inherit;
            right: 54px;
            //top: -37px;
          }
          .message-photo{
            margin-right: 0;
          }
        }
        .message-text{
          border-radius: 5px 5px 0px 5px;
          background-color: #DDE4F6;
        }
      }
      &.ttp{
        .message-text{
          color: #fff;
          background-color: #739EF2;
        }
      }
      
      &-photo{
        width: 44px;
        height: 44px;
        margin-right: 15px;
        overflow: hidden;
        border-radius: 100%;
        margin-top: 26.8px;
        @media screen and (max-width: 991px){
          margin-top: 0;
          margin-right: 0;
          margin-bottom: 10px;
        }
        img{
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
      &-content{
        flex: 1;
        position: relative;
        .message-dropdown{
          position: absolute;
          right: -45px;
          top: 26.8px;
          margin: 0;
          @media screen and (max-width: 991px){
            position: absolute;
            right: 0;
            top: -45px;
            margin: 0;
          }
        }
      }
      &-date{
        margin-bottom: 10px;
        font-size: 12px;
        line-height: 140%;
        color: rgba(24, 28, 50, 0.6);
        @media screen and (max-width: 991px){
          position: absolute;
          left: 54px;
          top: -40px;
        }
      }
      &-text{
        padding: 12px 20px;
        box-sizing: border-box;
        border-radius: 5px;
        background-color: ${({ theme }) => theme.colors.white};
        width: fit-content;
        text-align: left;
        display: inline-block;
      }
      &-name{
        font-weight: bold;
        font-size: 14px;
      }
      &-filter{
        display: inline-block;
        box-sizing: border-box;
        font-weight: 400;
        font-size: 14px;
        line-height: 140%;
        border-radius: 5px;
        text-decoration: underline;
        &.KNOLEDGEBASE{
          color: #D14893
        }
        &.LOCATION{
          color: #D14893;
        }
        &.PERSON{
          color: #5C8BE8;
        }
        &.PHONE_NUMBER{
          color: #189568;
        }
        &.EMAIL_ADDRESS{
          color: #D26547;
        }
        &.ORGANIZATION{
          color: #DE7F27;
        }
        &.IP_ADDRESS,
        &.URL,
        &.SSN,
        &.DATE_TIME{
          color: ${({ theme }) => theme.colors.black};
        }
      }
    }
`;

export const ChatButton = styled.button`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 11px 20px;
  box-sizing: border-box;
  font-weight: 500;
  font-size: 15px;
  line-height: 20px;
  border: 1px solid ${({ theme }) => theme.colors.blue};
  border-radius: 7px;
  color: ${({ theme }) => theme.colors.white};
  background-color: ${({ theme }) => theme.colors.blue};
  cursor: pointer;
  transition: opacity ease-in 0.1s, transform ease-in 0.1s;
  

  i{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 9px;
    path{
      transition: stroke ease-in 0.1s;
    }
  }

  &:hover{
    opacity: 0.9;
    i{
      path{
        stroke: ${({ theme }) => theme.colors.blue};
      }
    }
  }
  
  &.bordered{
    color: ${({ theme }) => theme.colors.blue};
    background-color: transparent;
    transition: background-color ease-in 0.1s, color ease-in 0.1s;
    &:hover{
      color: ${({ theme }) => theme.colors.white};
      background-color: ${({ theme }) => theme.colors.blue};
      opacity: 1;
    }
  }
  &.white{
    padding: 7px 20px;
    color: ${({ theme }) => theme.colors.black};
    background-color: ${({ theme }) => theme.colors.white};
    border-color: ${({ theme }) => theme.colors.white};
  }
  &.small{
    font-weight: 400;
    padding: 7px 16px;
    border-color: transparent;
    background-color: ${({ theme }) => theme.colors.blueLight};
    color: ${({ theme }) => theme.colors.black};
    transition: border-color ease-in 0.1s, color ease-in 0.1s;
    &:hover{
      border-color: ${({ theme }) => theme.colors.blue};
      color: ${({ theme }) => theme.colors.blue};
    }
  }
  &:active{
    transform: scale(0.99);
  }
  
`;

export const ChatFolders = styled.div`
  padding: 20px;
  box-sizing: border-box;
  border-bottom: 1px solid ${({ theme }) => theme.colors.borderColor};
  .folder{
    display: flex;
    align-items: center;
    padding: 8px;
    box-sizing: border-box;
    border-radius: 5px;
    cursor: pointer;
    position: relative;
    transition: opacity ease-in 0.1s;
    &:hover{
      opacity: 0.8;
    }
    &-arrow{
      width: 18px;
      height: 18px;
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      right: 0;
      top: 50%;
      transform: translate(0, -50%);
    }
    &-icon{
      width: 24px;
      height: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 10px;
    }
    &-text{
      flex :1;
    }
    &:not(.active){
      color: ${({ theme }) => theme.colors.black};
    }
    &.current{
      background-color: ${({ theme }) => theme.colors.blueLight};
      color: ${({ theme }) => theme.colors.black};
    }
  }
`;

export const ChatPlan = styled.div`
  padding: 20px;
  box-sizing: border-box;
  background: linear-gradient(268.67deg, #92CFE6 -3.77%, #7099F2 103.95%);
  border-radius: 5px;
  color: ${({ theme }) => theme.colors.white};
  .title{
    display: flex;
    align-items: center;
    font-weight: 700;
    font-size: 16px;
    line-height: 140%;
    margin-bottom: 15px;
    i{
      margin-left: 10px;
    }
  }
  .info{
    font-size: 12px;
    line-height: 20px;
    margin-bottom: 25px;
    &-title{
      font-size: 14px;
      line-height: 20px;
      span{
        font-weight: 500;
      }
    }
  }
  .progress{
    background: #869FCF;
    border-radius: 10px;
    position: relative;
    height: 7px;
    overflow: hidden;
    margin: 10px 0;
    span{
      position: absolute;
      left: 0;
      top: 0;
      display: block;
      background-color: ${({ theme }) => theme.colors.white};
      width: 65%;
      height: 100%;
    }
  }
`;

export const ChatControls = styled.div`
  position: absolute;
  background-color: ${({ theme }) => theme.colors.white};
  z-index: 9999;
  right: 0;
  top: 0;
  min-height: 100vh;
  padding: 60px 30px 30px 30px;
  display: flex;
  flex-direction: column;
  opacity: 0;
  will-change: transform;
  transform: translate(100%, 0);
  max-width: 507px;
  overflow-y: auto;
  height: 100vh;
  transition: transform .5s cubic-bezier(0.22, 1, 0.36, 1),opacity ease-out .4s;
  @media screen and (max-width: 991px){
    width: 100vw;
    height: calc(100vh - 66px);
    max-height: calc(100vh - 66px);
    min-height: calc(100vh - 66px);
    overflow-y: auto;
    top: 66px;
    transform: translate(0, 0);
    opacity: 0;
    visibility: hidden;
    padding: 20px;
    transition: opacity ease-out .2s, visibility ease-out .2s;
  }
  &.active{
    opacity: 1;
    will-change: transform;
    transform: translate(0, 0);
    @media screen and (max-width: 991px){
      opacity: 1;
      visibility: visible;
    }
  }
  .controls-close{
    cursor: pointer;
    width: 22px;
    height: 22px;
    display: flex;
    align-items: center;
    justify-content: center;
    right: 30px;
    top: 20px;
    position: absolute;
    transition: opacity ease-in 0.1s, transform ease-in 0.1s;
    @media screen and (max-width: 991px){
      right: 20px;
    }
    &:hover{
      opacity: 0.8;
    }
    &:active{
      transform: scale(0.9);
    }
  }
  .controls-header{
    &:not(:last-child){
      margin-bottom: 30px;
    }
    &-title{
      font-weight: 500;
      font-size: 16px;
      line-height: 23px;
      color: ${({ theme }) => theme.colors.black};
      &:not(:last-child){
        margin-bottom: 10px;
      }
      &.large{
        font-size: 18px;
      }
    }
  }
  .controls-items{
    flex: 1;
    .control{
      display: flex;
      align-items: center;
      margin-bottom: 20px;
      @media screen and (max-width: 576px){
        flex-direction: column;
        align-items: flex-start;
      }
      &-name{
        width: 110px;
        font-weight: 500;
        color: ${({ theme }) => theme.colors.black};
        @media screen and (max-width: 576px){
          width: 100%;
          margin-bottom: 10px;
        }
      }
      &-controls{
        display: flex;
        align-items: center;
        flex: 1;
        @media screen and (max-width: 576px){
          width: 100%;
        }
        label{
          position: relative;
          margin-right: 10px;
          flex: 1;
          @media screen and (max-width: 576px){
            flex: 1;
            &:last-child{
              margin-right: 0;
            }
          }
          input{
            position: absolute;
            left: 0;
            top: 0;
            width: 1px;
            height: 1px;
            opacity: 0;
            &:checked + span{
              background-color: ${({ theme }) => theme.colors.blueLight};
              border-color: ${({ theme }) => theme.colors.blue};
              color: ${({ theme }) => theme.colors.blue};
            }
          }
          span{
            display: inline-block;
            padding: 7.5px 15px;
            border: 1px solid #EEEEF1;
            border-radius: 5px;
            cursor: pointer;
            flex: 1;
            text-align: center;
            width: 100%;
            transition: background-color ease-in 0.1s, color ease-in 0.1s, border-color ease-in 0.1s;
            @media screen and (max-width: 576px){
              display: block;
              text-align: center;
            }
          }
        }
      }
    }
  }
  .controls-footer{
    display: flex;
    align-items: center;
    button{
      @media screen and (max-width: 576px){
        width: 100%;
      }
    }
    .active{
      position: relative;
      left: -9px;
      top: 0;
      transform: none;
      margin-left: 0;
    }
  }
  .controls-item{
    border-bottom: 1px solid #EEEEF1;
    padding-bottom: 24px;
    margin-bottom: 24px;
  }
  .controls-mods{
    display: flex;
    align-items: center;
    @media screen and (max-width: 576px){
      flex-direction: column;
      align-items: flex-start;
    }
    &-item{
      &:not(:last-child){
        margin-right: 30px;
        @media screen and (max-width: 576px){
          margin-right: 0;
          margin-bottom: 15px;
        }
      }
      display: block;
      input{
        display: none;
        &:checked + span{
          &:before{
            background-color: #739EF2;
            border-color: #739EF2;
          }
          &:after{
            opacity: 1;
          }
        }
      }
      span{
        display: block;
        position: relative;
        padding-left: 30px;
        font-size: 14px;
        font-weight: 500;
        color: #231F1F;
        &:before,
        &:after{
          content: "";
          position: absolute;
          left: 0;
          top: 50%;
          transform: translate(0, -50%);
          width: 20px;
          height: 20px;
          border-radius: 5px;
          cursor: pointer;
          border: 1px solid transparent;
          transition: all ease-in 0.1s;
        }
        &:before{
          border-color: #A4A4A4;
        }
        &:after{
          background-repeat: no-repeat;
          background-position: center;
          background-image: url(${checkIcon});
          opacity: 0;
        }
      }
    }
  }
  .controls-api{
    &-item{
      display: flex;
      &:not(:last-child){
        margin-bottom: 30px;
      }
      &-type{
        width: 50%;
        display: flex;
        align-items: center;
        &-logo{
          width: 20px;
          height: 20px;
          margin-right: 10px;
          img{
            width: 100%;
          }
        }
        &-name{
          font-size: 14px;
          color: #231F1F;
        }
      }
      &-set{
        color: #739EF2;
        font-weight: 500;
        font-size: 14px;
        cursor: pointer;
      }
    }
  }
`;

export const ChatOverlay = styled.div`
  position: fixed;
  width: 100vw;
  height: 100vh;
  left: 0;
  top: 0;
  z-index: 999;
  background: #000000;
  opacity: 0;
  will-change: auto;
  visibility: hidden;
  transition: all ease-out .2s;
  &.active{
    transition: all ease-out .2s;
    visibility: visible;
    opacity: 0.3;
  }
  @media screen and (max-width: 991px){
    display: none;
  }
`;

export const ProgramChatTyping = styled.div`
  .typing {
    width: 60px;
    height: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    background: ${({ theme }) => theme.colors.white};;
    border-radius: 20px;
  }

  .typing__dot {
    width: 6px;
    height: 6px;
    margin: 0 3px;
    background: ${({ theme }) => theme.colors.blue};;
    border-radius: 50%;
    opacity: 0;
    animation: loadingFade 1s infinite;
  }

  .typing__dot:nth-child(1) {
    animation-delay: 0s;
  }

  .typing__dot:nth-child(2) {
    animation-delay: 0.2s;
  }

  .typing__dot:nth-child(3) {
    animation-delay: 0.4s;
  }

  @keyframes loadingFade {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 0.8;
    }
    100% {
      opacity: 0;
    }
  }
`;

export const ProgrammDropdown = styled.div`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  cursor: pointer;
  width: 30px;
  height: 30px;
  background-color: #F5F4F9;
  position: relative;
  margin-right: 15px;
  transition: opacity ease-in 0.1s, transform ease-in 0.1s;
  &:hover{
    opacity: 0.8;
  }
  &:active{
    transform: scale(0.94);
  }
  &.white{
    background-color: #fff;
  }
`;

export const ProgramAiSelect = styled.div`
  .ai-select{
    position: relative;
    border-right: 1px solid #EEEEF1;
    &-current{
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      min-height: 60px;
      padding: 0 38px 0 15px;
      position: relative;
      &-icon{
        width: 24px;
        height: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
        img{
          display: block;
        }
      }
      &:after{
        content: "";
        width: 18px;
        height: 18px;
        position: absolute;
        right: 15px;
        top: 50%;
        transform: translate(0, -50%);
        background-position: center;
        background-repeat: no-repeat;
        background-image: url(${selectArrow});
        transition: transform ease-in-out 0.2s;
      }
      &.active{
        &:after{
          transform: translate(0, -50%) rotate(180deg);
        }
      }
    }
    &-items{
      position: absolute;
      bottom: calc(100% + 10px);
      left: 10px;
      width: 170px;
      border-radius: 5px;
      background-color: #ffffff;
      padding: 2px;
      box-sizing: border-box;
      opacity: 0;
      visibility: hidden;
      transform: scale(0.98);
      transition: visibility ease-in-out .2s,opacity ease-in-out .2s,transform ease-in-out .2s;
      &.active{
        opacity: 1;
        visibility: visible;
        transform: scale(1);
      }
      &-item{
        display: flex;
        align-items: center;
        transition: background-color ease-in-out 0.15s, transform ease-in 0.1s;
        padding: 9px 15px;
        box-sizing: border-box;
        border-radius: 5px;
        cursor: pointer;
        &:hover{
          background-color: #EAF1FD;
        }
        &:active{
          transform: scale(0.99);
        }
      }
      &-icon{
        width: 20px;
        height: 20px;
        display: flex;
        align-items: center;
        margin-right: 8px;
      }
      &-name{
        flex: 1;
        color: #181C32;
        font-size: 14px;
      }
    }
  }
`;

export const ChatModal = styled.div`
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 9999;
  background-color: #fff;
  padding: 24px;
  box-sizing: border-box;
  border-radius: 20px;
  text-align: center;
  max-width: 395px;
  width: 100%;
  display: none;
  &.active{
    display: block;
    animation: fadeInModal 0.3s ease-in-out;
  }
  .modal-logo{
    width: 44px;
    height: 44px;
    margin-left: auto;
    margin-right: auto;
    flex-shrink: 0;
  }
  .modal-close{
    position: absolute;
    right: 15px;
    top: 15px;
    cursor: pointer;
    color: #739EF2;
  }
  @keyframes fadeInModal {
    from {
      opacity: 0;
      visibility: hidden;
      transform: translate(-50%, -50%) scale(0.97);
    }

    to {
      opacity: 1;
      visibility: visible;
      transform: translate(-50%, -50%) scale(1);
    }
  }
  .modal-title{
    color: #121111;
    text-align: center;
    font-family: Mignon;
    font-size: 30px;
    font-style: normal;
    font-weight: 200;
    line-height: 100%; /* 55px */
    margin-bottom: 20px;
    span{
      color: #739EF2;
    }
  }
  button{
    border-radius: 5px;
    height: 42px;
    font-size: 16px;
    font-weight: 500;
    border: none;
    width: 100%;
    display: block;
    &:not(:last-child){
      margin-bottom: 20px;
    }
  }
  .json{
    background: linear-gradient(268.67deg, #92CFE6 -3.77%, #7099F2 103.95%);
    color: #fff;
  }
  .xlsx{
    background: linear-gradient(268.67deg, #92CFE6 -3.77%, #7099F2 103.95%);
    color: #fff;
  }
  .csv{
    background: linear-gradient(268.67deg, #92CFE6 -3.77%, #7099F2 103.95%);
    color: #fff;
  }
  .signin{
    background: linear-gradient(268.67deg, #92CFE6 -3.77%, #7099F2 103.95%);
    color: #fff;
  }
  .close{
    background: rgba(115, 158, 242, 0.15);
    color: #181C32;
  }
`;


export const ChatPulse = styled.div`{
  width: 18px;
  height: 18px;
  position: absolute;
  background-color: #74AA9C;
  left: -9px;
  top: 50%;
  transform: translate(0, -50%);
  border-radius: 100%;
  opacity: 0;
  visibility: hidden;
  z-index: 2;
  animation: pulse 2s infinite;
  transition: opacity ease-in 0.1s, visibility ease-in 0.1s;
  &.active{
    opacity: 1;
    visibility: visible;
  }
  @keyframes pulse {
    0% {
      box-shadow: 0 0 0 0 rgb(116, 170, 156, 0.25);
    }
    70% {
      box-shadow: 0 0 0 20px rgb(116, 170, 156, 0);
    }
    100% {
      box-shadow: 0 0 0 0 rgb(116, 170, 156, 0);
    }
  }
}`;