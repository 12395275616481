import { observer } from "mobx-react-lite";
import React from "react";

import { Account } from "../../components";

export const AccountApp: React.FC = observer(() => {
    return (
        <>
            <Account />
        </>
    );
});
