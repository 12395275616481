import React, { useRef, useState, useEffect } from "react";
import { observer } from "mobx-react-lite";
import { GoogleOAuthProvider, useGoogleLogin, googleLogout, TokenResponse } from "@react-oauth/google";
import axios from 'axios';
import { MultiContext } from "../../contexts";
import { LogInButton } from "./OAuth.styles"

import google from "../../assets/google.png"

export const GoogleOAuth: React.FC = observer(() => {
    const [ user, setUser ] = useState<TokenResponse | null>(null);
    const [ profile, setProfile ] = useState<any>(null)
    ;
    const globalStores = React.useContext(MultiContext);

    const login = useGoogleLogin({
        onSuccess: (codeResponse) => setUser(codeResponse),
        onError: (error) => console.log('Login Failed:', error)
    });

    useEffect(
        () => {
            if (user) {
                axios.get(
                    `https://www.googleapis.com/oauth2/v1/userinfo?access_token=${user.access_token}`, {
                        headers: {
                            Authorization: `Bearer ${user.access_token}`,
                            Accept: 'application/json'
                        }
                    })
                    .then((res) => {
                        globalStores.auth.processSignIn(
                            res.data.email,
                            null,
                            user.access_token,
                            "google"
                        )
                        globalStores.user.setEmail(res.data.email);
                        globalStores.user.setName(res.data.name);
                        globalStores.user.setProfileImgUrl(res.data.picture);
                    })
                    .catch((err) => console.log(err));
            }
        },
        [ user ]
    );

    // log out function to log the user out of google and set the profile array to null
    const logOut = () => {
        googleLogout();
        setProfile(null);
    };

    return (
        <LogInButton>
            <button onClick={() => login()}> 
                <div>
                    <img className="button-icone" src={google}></img>
                </div>
            </button>
        </LogInButton>
    )
});

export const OAuth: React.FC = (() => {

    return <div className="App">
        <GoogleOAuthProvider clientId="709514573024-d87bksmu2s8jcs6epn5oiqrqeif0ioon.apps.googleusercontent.com">
            <React.StrictMode>
                <GoogleOAuth/>
            </React.StrictMode>
        </GoogleOAuthProvider>
    </div>
});