import { Route, Routes } from "react-router-dom";
import { ThemeProvider } from "styled-components";

import { ProgrammApp, SignInApp, SignUpApp, AccountApp } from "./apps";
import settings from "./common/settings";
import {
    AuthZoneWrapper,
    MultiContextProvider
} from "./components";
import { GlobalStyle, baseTheme } from "./styles";

export const App = () => {
    return (
        <MultiContextProvider>
                <ThemeProvider theme={baseTheme}>
                    <GlobalStyle />
                    <Routes>
                        <Route
                            path={settings.INNER_ROUTES.signIn}
                            element={<SignInApp />}
                        />
                        <Route
                            path={settings.INNER_ROUTES.signUp}
                            element={<SignUpApp />}
                        />
                        <Route
                            path={settings.INNER_ROUTES.account}
                            element={<AccountApp />}
                        />
                        <Route
                            path={settings.INNER_ROUTES.programm}
                            element={
                                <ProgrammApp />
                            }
                        />
                    </Routes>
                </ThemeProvider>
        </MultiContextProvider>
    );
};
