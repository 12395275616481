export const baseTheme = {
    colors: {
        bgMain: "#EAF1EB",
        white: "#ffffff",
        green: "#74B72E",
        greenLight: "#99EDC3",
        borderColor: "#EEEEF1",
        black: "#181C32",
        grey: "#4E4E4E",
        bgDark: "rgb(0,0,0)",
        bgGrey: "#f4f4f4",
        fontGrey: "#808080",
    },
};
