import styled from "styled-components";

export const Button = styled.button`
    display: inline-block;
    position: relative;
    z-index: 10;
    padding: 0.8rem 2rem;
    border: none;
    border-radius: 40px;
    font-size: 1rem;
    font-weight: 400;
    color: white;
    background-image: linear-gradient(
        90deg,
        rgb(112, 153, 242) 0%,
        rgb(147, 208, 230) 100%
    );
    cursor: pointer;
    transition: box-shadow 0.3s ease-in-out;
    &:hover {
        box-shadow: 0px 0px 20px ${({ theme }) => theme.colors.blueLight};
    }
    &:active {
        transform: translateY(2px);
    }
    @media (hover: none) {
        &:hover {
            transition: none;
            box-shadow: none;
        }
    }
`;
