import React from "react";

import { SignInForm } from "../../components";

export const SignInApp:React.FC = () => {
    return (
        <>
            <SignInForm/>
        </>
    )
};
