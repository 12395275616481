import styled from "styled-components";

export const LogInButton = styled.button`
  border: none;
  height: 60px;
  width: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  cursor: pointer;
  outline: none;
  transition: opacity ease-in 0.1s, transform ease-in 0.1s;
  .button-icone {
    height: 60%;
    width: 60%;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
  }
  path{
    transition: fill ease-in 0.1s, fill-opacity ease-in 0.1s;
  }
  &:hover{
    path{
      fill: ${({ theme }) => theme.colors.blue};
      fill-opacity: 1;
    }
  }
  &:active{
    transform: scale(0.99);
  }
`;