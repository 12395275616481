import { observer } from "mobx-react-lite";
import React from "react";

import { MultiContext } from "../../contexts";
import { buildAllStores } from "../../stores";

const allStores = buildAllStores();

interface MultiContextProps {
    children: React.ReactNode;
}

export const MultiContextProvider: React.FC<MultiContextProps> = observer((props: MultiContextProps) => {
    return (
        <MultiContext.Provider value={allStores}>
            {props.children}
        </MultiContext.Provider>
    );
});