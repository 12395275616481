const ROOT_DOMAIN_ADDRESS = "https://bijection.tech/api";
const ROOT_API_ADDRESS = `${ROOT_DOMAIN_ADDRESS}`;

const settings = {
    API_ROUTES: {
        signIn: `${ROOT_API_ADDRESS}/token`,
        signUp: `${ROOT_API_ADDRESS}/users`,
        me: `${ROOT_API_ADDRESS}/users/me`,
        accessTokenValidity: `${ROOT_API_ADDRESS}/validate`,
        extractFromHTML: `${ROOT_API_ADDRESS}/extract_from_html`,
        extractFromURL: `${ROOT_API_ADDRESS}/extract_from_url`,
        extractFromText: `${ROOT_API_ADDRESS}/extract_from_text`,
        highlightFromHTML: `${ROOT_API_ADDRESS}/highlight_from_html`,
        highlightFromURL: `${ROOT_API_ADDRESS}/highlight_from_url`,
        highlightFromHTMLNoToken: `${ROOT_API_ADDRESS}/highlight_from_html_no_token`,
        highlightFromURLNoToken: `${ROOT_API_ADDRESS}/highlight_from_url_no_token`,
        download: `${ROOT_API_ADDRESS}/tasks`,
    },
    INNER_ROUTES: {
        programm: "/",
        signIn: "/login/",
        signUp: "/sign-up/",
        request: "/request/",
        account: "/account/",
        termsAndConditions: "/terms-and-conditions/"
    },
    MESSAGES: {
        HttpRequestFailed: "HTTP request failed",
        validationError: "HTTP request data validation error",
    },
    ONBOARDING : {
        steps : [
            {
                step : 1,
                title : "1. <span>To The Point</span> prevents risky use",
                percent : "10%",
                description : "",
                footer : false
            },
            {
                step : 2,
                title : "1. <span>To The Point</span> prevents risky use",
                percent : "20%",
                description : "<span>To The Point</span> is guiding against the use of LLMs when their limited knowledge makes AI an unsuitable choice.",
                footer : true
            },
            {
                step : 3,
                title : "2. <span>To The Point</span> anonymises confidential data",
                percent : "30%",
                description : "",
                footer : false
            },
            {
                step : 4,
                title : "2. <span>To The Point</span> anonymises confidential data",
                percent : "40%",
                description : "<span>To The Point</span> guarantees the encryption of your sensitive information throughout all interactions with LLM",
                footer : true
            },
            {
                step : 5,
                title : "3. Collect internal knowledge scattered across the enterprise",
                percent : "50%",
                description : "",
                footer : false
            },
            {
                step : 6,
                title : "3. Collect internal knowledge scattered across the enterprise",
                percent : "60%",
                description : "<span>To The Point</span> ensures factual consistency by validating information based on the provided data.",
                footer : true
            },
            {
                step : 7,
                percent : "70%",
                title : "4. Settings",
                description : "",
                footer : false
            },
            {
                step : 8,
                percent : "80%",
                title : "4. Settings",
                description : "Here you can customize preventive measures for risky usage, configure the level of privacy for your chat, and connect knowledge sources to it.",
                footer : true
            }
        ]
    }
};

export default settings;

