import * as zod from "zod";
import React, { useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import settings from "./settings";
import { MultiContext } from "../contexts";

export enum WhatInResponseFailedEnum {
    nothing = 0,
    requestFailed = 1,
    validationError = 2,
}
export interface HttpSchemaResponseAnswer {
    whatFailed: WhatInResponseFailedEnum;
    payload: undefined | unknown;
    statusCode: undefined | number;
}
export const runHttpRequestWithSchemaDeserializtion = async (
    urlAddress: string,
    methodName: "get" | "post" | "put" | "delete",
    payloadFields: string | URLSearchParams,
    shemaObject: zod.ZodTypeAny,
    headers: { [key: string]: string },
    accessToken?: string
): Promise<HttpSchemaResponseAnswer> => {
    const responseStruct: HttpSchemaResponseAnswer = {
        whatFailed: WhatInResponseFailedEnum.nothing,
        payload: undefined,
        statusCode: undefined,
    };
    const serverResult = await fetch(urlAddress, {
        method: methodName,
        headers: headers,
        body: payloadFields,
    });
    responseStruct.statusCode = serverResult.status;
    if (!serverResult.ok) {
        console.error(
            `Http request to ${urlAddress} failed. Error: ${serverResult.statusText}`
        );
        responseStruct.whatFailed = WhatInResponseFailedEnum.requestFailed;
        return responseStruct;
    }
    const serverData = await serverResult.json();
    const validationResult = await shemaObject.safeParseAsync(serverData);
    if (!validationResult.success) {
        console.error(validationResult);
        responseStruct.whatFailed = WhatInResponseFailedEnum.validationError;
    } else {
        responseStruct.payload = validationResult.data;
    }
    return responseStruct;
};

export const requestAPI = async (requestMethod: any,  methodType: string, requestData: any = null) => {

    const token = localStorage.getItem("jwt");
    const meta = (requestData != null) ? {
        method: methodType,
        headers: {
            accept: "application/json",
            "Content-Type": "application/json",
            "Access-Control-Allow-Credentials": "*",
            Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(requestData),
    } : {
        method: methodType,
        headers: {
            accept: "application/json",
            "Content-Type": "application/json",
            "Access-Control-Allow-Credentials": "*",
            Authorization: `Bearer ${token}`,
        },
    }

    return await fetch(
        requestMethod,
        meta
    );
};
