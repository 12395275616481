import styled from "styled-components";

export const AuthFormWrap = styled.section`
    padding-top: 0.3rem;
    padding-bottom: 0;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: ${({ theme }) => theme.colors.bgMain};
`;

export const AuthForma = styled.form`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 60%;
    max-width: 40rem;
    min-width: 15rem;
    margin-bottom: 3rem;
    img {
        height: 7rem;
        width: 7rem;
    }
    h2 {
        padding-top: 1.5rem;
        padding-bottom: 0;
    }
    span {
        color: ${({ theme }) => theme.colors.blueDark1};
    }
    button {
        margin-top: 2.5rem;
        padding: 1rem 3rem;
    }
    a {
        color: ${({ theme }) => theme.colors.blueDark1};
        padding-top: 1rem;
    }
`;

export const AuthFormInputWrap = styled.div`
    font-size: 1.1rem;
    width: 100%;
    position: relative;
    z-index: 11;
    margin-top: 1.5rem;
    border-radius: 90px;
    &::after {
        content: "";
        position: absolute;
        border-radius: 90px;
        width: 100%;
        height: 100%;
        z-index: -1;
        top: 0px;
        left: 0px;
        background-image: linear-gradient(
            90deg,
            rgb(112, 153, 242) 0%,
            rgb(147, 208, 230) 100%
        );
        -webkit-transition: filter 0.5s;
        transition: filter 0.5s;
    }
    &:hover:after {
        filter: blur(8px);
        outline: 0.5px solid ${({ theme }) => theme.colors.blueLight};
    }
    input {
        display: inline-block;
        z-index: 9;
        position: relative;
        border-radius: 30px;
        border: 0.5px solid grey;
        font-size: 1.1rem;
        padding: 0.8rem;
        width: 100%;
        -webkit-transition: filter 0.5s;
        &::placeholder {
            font-weight: 300;
        }
    }
    .react-select {
        outline: none;
        &__control {
            padding: 0.3rem;
            outline: 0.5px solid grey;
            border-radius: 30px;
            &:focus {
                outline: 0.5px solid grey;
            }
        }
        &__placeholder {
            font-size: 1.1rem;
            font-weight: 300;
            font: "Montserrat",sans-serif;
            justify-self: start;
        }
        &__single-value {
            justify-self: start;
        }
        &__menu {
            position: absolute;
            height: fit-content;
            z-index: 100;
        }
    }
`;
