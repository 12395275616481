import styled from "styled-components";
import mainBgMobile from '../../assets/main-bg-mobile.jpg';
import mainBg from '../../assets/main-bg.jpg';

export const SignInFormWrap = styled.section`
    padding-top: 0.3rem;
    padding-bottom: 0;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: ${({ theme }) => theme.colors.bgMain};
`;

export const SignInFormInputWrap = styled.div`
    font-size: 1.1rem;
    width: 100%;
    position: relative;
    z-index: 50;
    margin-top: 1.5rem;
    border-radius: 90px;
    input {
        display: inline-block;
        position: relative;
        z-index: 9;
        border-radius: 30px;
        border: 0.5px solid grey;
        font-size: 1.1rem;
        padding: 0.8rem;
        width: 100%;
        -webkit-transition: filter 0.5s;
        transition: box-shadow 0.4s ease-in-out;
        &:hover {
            box-shadow: 0px 0px 25px ${({ theme }) => theme.colors.blueLight};
        }
        &::placeholder {
            font-weight: 300;
        }
        @media (hover: none) {
            &:hover {
                -webkit-transition: box-shadow 0.5s;
                transition: none;
                box-shadow: none;
            }
        }
    }
`;

export const SignInButton = styled.button`
    display: inline-block;
    position: relative;
    z-index: 10;
    padding: 0.8rem 2rem;
    border: none;
    border-radius: 40px;
    font-size: 1rem;
    font-weight: 400;
    color: white;
    background-image: linear-gradient(
        90deg,
        rgb(112, 153, 242) 0%,
        rgb(147, 208, 230) 100%
    );
    cursor: pointer;
    transition: box-shadow 0.3s ease-in-out;
    &:hover {
        box-shadow: 0px 0px 20px ${({ theme }) => theme.colors.blueLight};
    }
    &:active {
        transform: translateY(2px);
    }
    @media (hover: none) {
        &:hover {
            transition: none;
            box-shadow: none;
        }
    }
`;

export const AuthWrap = styled.div`
  display: flex;
  min-height: 100vh;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  background-color: #fff;
  background-image: url(${mainBg});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  @media screen and (max-width: 768px){
    padding: 30px 20px;
    box-sizing: border-box;
  }
  @media screen and (max-width: 576px){
    background-image: url(${mainBgMobile});
  }
`;


export const AuthLogo = styled.div`
    padding-top: 65px;
    width: 198px;
      @media screen and (max-width: 768px){
        padding-top: 0;
        margin-bottom: 40px;
      }
  @media screen and (max-width: 576px){
    width: 158px;
  }
    img{
      display: block;
      width: 100%;
    }
`;

export const AuthForm = styled.form`
  margin: auto 0;
  background-color: #fff;
  border: 1px solid #EEEEF1;
  max-width: 464px;
  width: 100%;
  padding: 30px 45px;
  border-radius: 10px;
  box-shadow: 0 30px 30px rgba(0, 0, 0, 0.03), 0px 8px 250px rgba(0, 0, 0, 0.02);
  @media screen and (max-width: 576px){
    padding: 30px 20px;
  }
`;

export const AuthFormHeader = styled.div`
  margin-bottom: 30px;
  text-align: center;
  @media screen and (max-width: 576px){
    margin-bottom: 20px;
  }
  h1{
    font-size: 24px;
    font-weight: 500;
    color: #181C32;
    margin: 0;
    padding: 0;
  }
`;
export const AuthFormInput = styled.input`
  border-radius: 5px;
  border: 1px solid var(--line, #EEEEF1);
  background: #F8F8F8;
  min-height: 46px;
  margin-bottom: 20px;
  width: 100%;
  padding: 0 15px;
  outline: none;
  font-size: 16px;
  font-family: inherit;
  transition: border-color ease-in 0.1s;
  &::placeholder{
    color: #A4A4A4;
  }
  &:-ms-input-placeholder{
    color: #A4A4A4;
  }
  &::-ms-input-placeholder{
    color: #A4A4A4;
  }
  &:focus{
    border-color: #74B72E;
  }
`;


export const AuthButton = styled.button`
  border-radius: 5px;
  background: var(--1, linear-gradient(239deg, #92CFE6 0%, #7099F2 100%));
  min-height: 46px;
  border: none;
  font-size: 16px;
  font-weight: 500;
  color: #fff;
  cursor: pointer;
  width: 100%;
  margin: 10px 0 0 0!important;
  box-shadow: none!important;
  transition: opacity ease-in 0.1s, transform ease-in 0.1s;
  &:hover{
    opacity: 0.9;
  }
  &:active{
    transform: scale(0.99);
  }
`;

export const AuthFormContent = styled.div`
  margin-bottom: 30px;
  .react-select__control{
    border-radius: 5px;
    border: 1px solid #EEEEF1;
    background-color: #F8F8F8;
    min-height: 46px!important;
    margin-bottom: 20px;
    width: 100%;
    padding: 0 0 0 15px!important;
    outline: none;
    font-size: 16px;
    font-family: inherit;
    &:hover{
      border-color: #EEEEF1;;
    }
    .css-1jqq78o-placeholder{
      color: #A4A4A4!important;
    }
    .css-1fdsijx-ValueContainer{
      padding: 0;
    }
    &:focus{
      border-color: #74B72E;
    }
  }
`;

export const AuthFormFooter = styled.div`
  text-align: center;
  a{
    color: #74B72E;
    &:hover{
      text-decoration: underline;
    }
  }
  .footer-error{
    margin-top: 5px;
    color: #99EDC3;
  }
`;



/* Don't have an account? Sign up */


