import { makeAutoObservable } from "mobx";

import {
    HttpSchemaResponseAnswer,
    WhatInResponseFailedEnum,
    runHttpRequestWithSchemaDeserializtion,
} from "../common/http";
import { SignInResponseSchema, SignUpResponseSchema } from "../common/schemas";
import settings from "../common/settings";
import { requestAPI } from "../common/http"
import { ZodNullable } from "zod";

export class AuthStore {
    isAuthorized: boolean = false;
    accessToken: string = "";
    forcedLogout: boolean = false;
    limit: number = 0;
    requestCount: number = 0;

    constructor() {
        makeAutoObservable(this);
    }

    setIsAuthorized(authState: boolean): void {
        this.isAuthorized = authState;
    }
    setAccessToken(accessToken: string): void {
        this.accessToken = accessToken;
    }
    setForcedLogout(forcedLogoutState: boolean): void {
        this.forcedLogout = forcedLogoutState;
    }
    setRequestCount(requestCount: number): void {
        this.requestCount = requestCount;
    }
    setLimit(limit: number): void {
        this.limit = limit;
    }

    processSignIn = async (
        email: string,
        password: string | null = null,
        token: string | null = null,
        scope: string = "email"
    ): Promise<HttpSchemaResponseAnswer> => {
        const request_jsn = scope == "email" ? {
            username: email,
            password: password,
            scope: "email"
        } : {
            username: email,
            password: token,
            scope: "google"
        }
        const body = new URLSearchParams(
            JSON.parse(
                JSON.stringify(request_jsn)
            )
        );
        const signInResponse = await runHttpRequestWithSchemaDeserializtion(
            settings.API_ROUTES.signIn,
            "post",
            body,
            SignInResponseSchema,
            {
                accept: "application/json",
                "Access-Control-Allow-Credentials": "*",
                "Content-Type": "application/x-www-form-urlencoded",
            },
            "coded"
        );
        switch (signInResponse.whatFailed) {
            case WhatInResponseFailedEnum.nothing:
                this.setAccessToken(
                    SignInResponseSchema.parse(signInResponse.payload)
                        .access_token
                );

                this.setIsAuthorized(true);
                localStorage.setItem(
                    "jwt",
                    SignInResponseSchema.parse(signInResponse.payload)
                        .access_token
                );

                const meResponse = await requestAPI(
                    settings.API_ROUTES.me, "get"
                );
                const meData = await meResponse.json();
                this.setLimit(meData.limit);
                this.setRequestCount(meData.request_count);

                return signInResponse;
            case WhatInResponseFailedEnum.requestFailed:
                console.log(
                    `Sign in failed: ${settings.MESSAGES.HttpRequestFailed}.`
                );
                return signInResponse;
            case WhatInResponseFailedEnum.validationError:
                console.log(
                    `Sign in failed: ${settings.MESSAGES.validationError}.`
                );
                return signInResponse;
        }
    };
    processSignUp = async (
        email: string,
        password: string,
        name: string,
        goalOfUse: string
    ) => {
        const body = JSON.stringify({
            email: email,
            password: password,
            name: name,
            field_of_interest: goalOfUse,
        });
        const signUpResponse = await runHttpRequestWithSchemaDeserializtion(
            settings.API_ROUTES.signUp,
            "post",
            body,
            SignUpResponseSchema,
            {
                accept: "application/json",
                "Access-Control-Allow-Credentials": "*",
                "Content-Type": "application/json",
            },
            "coded"
        );
        switch (signUpResponse.whatFailed) {
            case WhatInResponseFailedEnum.nothing:
                console.log("Sign up comleted");
                return signUpResponse;
            case WhatInResponseFailedEnum.requestFailed:
                console.log(
                    `Sign up failed: ${settings.MESSAGES.HttpRequestFailed}.`
                );
                return signUpResponse;
            case WhatInResponseFailedEnum.validationError:
                console.log(
                    `Sign up failed: ${settings.MESSAGES.validationError}.`
                );
                return signUpResponse;
        }
    };
    processSignOut = async () => {
        this.setIsAuthorized(false);
        localStorage.removeItem("jwt");
        console.log("Signed out successfully");
        this.setAccessToken("");
    };
    isAccessExpired = async () => {
        const token = localStorage.getItem("jwt");
        if (token) {
            try {
                const isTokenValid = await fetch(
                    settings.API_ROUTES.accessTokenValidity,
                    {
                        method: "post",
                        headers: {
                            accept: "application/json",
                            "Content-Type": "application/json",
                        },
                        body: JSON.stringify({
                            access_token: token,
                            token_type: "bearer",
                        }),
                    }
                );
                if (!isTokenValid.ok) {
                    this.setForcedLogout(true);
                } else {
                    this.setForcedLogout(false);
                }
            } catch (err: any) {
                console.log(err.message);
                this.setForcedLogout(true);
            }
        } else {
            this.setForcedLogout(false);
        }
    };
}
