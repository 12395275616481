import { observer } from "mobx-react-lite";
import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import settings from "../../common/settings";
import { MultiContext } from "../../contexts";

type UnauthProps = {
    children: React.ReactNode;
};

export const AuthZoneWrapper: React.FC<UnauthProps> = observer(
    (props: UnauthProps) => {
        const globalStores = React.useContext(MultiContext);
        const locationAPI = useLocation();
        const routerNavigate = useNavigate();
        const redirectToLogin = (): void => {
            routerNavigate(`${settings.INNER_ROUTES.signIn}`);
        };
        React.useEffect(() => {
            const token = localStorage.getItem("jwt");
            if (token) {
                (async () => {
                    await globalStores.auth.isAccessExpired();
                    if (globalStores.auth.forcedLogout) {
                        globalStores.auth.processSignOut();
                        routerNavigate(settings.INNER_ROUTES.signIn)
                    } else {
                        globalStores.auth.setIsAuthorized(true);
                    }
                })();
            } else {
                routerNavigate(settings.INNER_ROUTES.signIn);
            }
        }, [
            routerNavigate,
            locationAPI,
        ]);

        return <div>{props.children}</div>;
    }
);
