import React from "react";
import { Link, useNavigate } from "react-router-dom";

import { observer } from "mobx-react-lite";
import { validateEmail } from "../../common/helpers/validators";
import settings from "../../common/settings";
import { MultiContext } from "../../contexts";
import {
    AuthButton,
    AuthForm,
    AuthFormContent,
    AuthFormFooter,
    AuthFormHeader,
    AuthFormInput,
    AuthLogo,
    AuthWrap
} from "./SignInForm.styles";

import logo from "../../assets/logo.svg";

export const SignInForm: React.FC = observer(() => {
    const globalStores = React.useContext(MultiContext);
    const [data, setData] = React.useState({
        email: "",
        password: "",
    });

    const [error, setError] = React.useState({
        email: "",
        password: "",
    });

    const [generalError, setGeneralError] = React.useState("");
    const [errorMessage, setErrorMessage] = React.useState("");
    const [isWrong, setIsWrong] = React.useState(false);
    const navigate = useNavigate();

    const handleChangeInput = (evt: any) => {
        const { name, value } = evt.target;
        setData({
            ...data,
            [name]: value,
        });
    };

    const handleChangeInputError = (evt: any) => {
        console.log(error);
        setIsWrong(false);
        if (!evt.target.validity.valid) {
            const { name, value } = evt.target;
            setError({
                ...error,
                [name]: evt.target.validationMessage,
            });
            setErrorMessage(evt.target.validationMessage);
        } else {
            const { name, value } = evt.target;
            setError({
                ...error,
                [name]: "",
            });
        }
    };

    const handleSubmit = async (evt: any) => {
        evt.preventDefault();
        setGeneralError("");
        setIsWrong(true);
        try {

            if (!data.email && !data.password) {
                throw new Error("Email and password are required");
            }
            if (!data.email) {
                throw new Error("Email is required");
            }
            if (!data.password) {
                throw new Error("Password is required");
            }
            if (!validateEmail(data.email)) {
                throw new Error("Invalid Email");
            }
            const response = await globalStores.auth.processSignIn(
                data.email,
                data.password
            );
            try {
                if (response.whatFailed === 0) {
                    navigate(settings.INNER_ROUTES.programm);
                } else if (
                    response.whatFailed === 1 &&
                    response.statusCode === 401
                ) {
                    throw new Error("Invalid email or password");
                } else {
                    throw new Error(
                        "Something went wrong. Try again later or write to our email."
                    );
                }
            } catch (err: any) {
                setGeneralError(err.message);
            }
        } catch (err: any) {
            if (err.message === "Failed to fetch") {
                setGeneralError(
                    "Something went wrong. Try again later or write to our email."
                );
            } else {
                setGeneralError(err.message);
            }
        }
    };

    return (
        <AuthWrap>
            <AuthLogo>
                <img src={logo} alt="To The Point"/>
            </AuthLogo>
            <AuthForm autoComplete="off" noValidate>
                <AuthFormHeader>
                    <h1>Sign in</h1>
                </AuthFormHeader>
                <AuthFormContent>
                    <AuthFormInput
                        type="email"
                        name="email"
                        placeholder="Email Address*"
                        value={data.email}
                        onChange={(e) => {
                            handleChangeInput(e);
                            handleChangeInputError(e);
                        }}
                        required
                        className={isWrong && error.email ? "error" : ""}
                    >
                    </AuthFormInput>
                    <AuthFormInput
                        type="password"
                        name="password"
                        placeholder="Password*"
                        value={data.password}
                        onChange={(e) => {
                            handleChangeInput(e);
                            handleChangeInputError(e);
                        }}
                        required
                        className={isWrong && error.password ? "error" : "sfdsfd"}
                    >
                    </AuthFormInput>
                    <AuthButton type="submit" onClick={handleSubmit}>
                        Sign In
                    </AuthButton>
                </AuthFormContent>
                <AuthFormFooter>
                    <div className="footer-info">
                        Don't have an account?
                        <Link to={settings.INNER_ROUTES.signUp} style={{ textDecoration: "none" }}>
                            <span> Sign up</span>
                        </Link>
                    </div>
                    <div className="footer-error">
                        {generalError}
                    </div>
                </AuthFormFooter>
            </AuthForm>
        </AuthWrap>
    );
});
