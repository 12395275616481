import { makeAutoObservable } from "mobx";

export class UserStore {
    email: string = "";
    name: string = "";
    profileImgUrl: string = "";

    constructor() {
        makeAutoObservable(this);
    }

    setEmail(email: string): void {
        this.email = email;
        localStorage.setItem(
            "email",
            email
        );
    }
    setName(name: string): void {
        this.name = name;
        localStorage.setItem(
            "name",
            name
        );
    }
    setProfileImgUrl(profileImgUrl: string): void {
        this.profileImgUrl = profileImgUrl;
        localStorage.setItem(
            "profileImgUrl",
            profileImgUrl
        );
    }
}
