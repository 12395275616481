import { AuthStore, RequestStore, UserStore } from "./";

interface AllStoreType {
    auth: AuthStore;
    request: RequestStore;
    user: UserStore;
};

export const buildAllStores = (): AllStoreType => {
    return {
        auth: new AuthStore,
        request: new RequestStore,
        user: new UserStore
    };
};
