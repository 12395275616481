import React, { useRef, useState, useEffect } from "react";
import { observer } from "mobx-react-lite";
import { GoogleOAuthProvider, useGoogleLogin, googleLogout, TokenResponse } from "@react-oauth/google";
import axios from 'axios';

export const LoginApp: React.FC = observer(() => {
    const [ user, setUser ] = useState<TokenResponse | null>(null);
    const [ profile, setProfile ] = useState<any>(null);

    const login = useGoogleLogin({
        onSuccess: (codeResponse) => setUser(codeResponse),
        onError: (error) => console.log('Login Failed:', error)
    });

    useEffect(
        () => {
            if (user) {
                axios
                    .get(`https://www.googleapis.com/oauth2/v1/userinfo?access_token=${user.access_token}`, {
                        headers: {
                            Authorization: `Bearer ${user.access_token}`,
                            Accept: 'application/json'
                        }
                    })
                    .then((res) => {
                        setProfile(res.data);
                    })
                    .catch((err) => console.log(err));
            }
        },
        [ user ]
    );

    // log out function to log the user out of google and set the profile array to null
    const logOut = () => {
        googleLogout();
        setProfile(null);
    };

    return (
        <div>
            <h2>React Google Login</h2>
            <br />
            <br />
            {profile ? (
                <div>
                    <img src={profile.picture} alt="user image" />
                    <h3>User Logged in</h3>
                    <p>Name: {profile.name}</p>
                    <p>Email Address: {profile.email}</p>
                    <br />
                    <br />
                    <button onClick={logOut}>Log out</button>
                </div>
            ) : (
                <button onClick={() => login()}> Sign in with Google 🚀 </button>
            )}
        </div>
    )
});

export const Account: React.FC = (() => {

    return <div className="App">
        <GoogleOAuthProvider clientId="709514573024-d87bksmu2s8jcs6epn5oiqrqeif0ioon.apps.googleusercontent.com">
            <React.StrictMode>
                <LoginApp/>
            </React.StrictMode>
        </GoogleOAuthProvider>
    </div>
});