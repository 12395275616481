import { makeAutoObservable } from "mobx";

export class RequestStore {
    requestId: string = "";
    blockedStatus: boolean = true;

    constructor() {
        makeAutoObservable(this);
    }

    setRequestId(requestId: string): void {
        this.requestId = requestId;
        localStorage.setItem(
            "requestId",
            requestId
        );
    }
    setblockedStatus(isBlocked: boolean): void {
        this.blockedStatus = isBlocked;
        localStorage.setItem(
            "isBlocked",
            `${isBlocked}`
        );
    }
}
``