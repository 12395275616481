import React, { useRef, useState, useEffect } from "react";
import {
    ProgramButton,
    ProgramChat,
    ProgramChatTyping,
    ProgramForm,
    ProgramInput,
    ProgrammDropdown,
    ProgrammWrap,
    ChatModal,
    ChatButton,
} from "./ProgramUpload.styles";

import ReactDOMServer from "react-dom/server";

import { observer } from "mobx-react-lite";
import { useNavigate } from "react-router-dom";
import settings from "../../common/settings";
import { MultiContext } from "../../contexts";
import { requestAPI } from "../../common";
import { OAuth } from "../OAuth"
import { validHttpUrl } from "../../common/helpers/validators";

import SyntaxHighlighter from 'react-syntax-highlighter';
import { docco } from 'react-syntax-highlighter/dist/esm/styles/hljs';

import logo from "../../assets/logo.svg";

import bijectionAvatar from "../../assets/bijection-avatar.svg";
import userAvatar from "../../assets/user-avatar.jpg";
import { Dict } from "styled-components/dist/types";

localStorage.setItem(
    "entity2mask",
    JSON.stringify({})
);

export const ProgrammUpload: React.FC = observer(() => {

    const globalStores = React.useContext(MultiContext);
    const routerNavigate = useNavigate();

    const refInputField = useRef<null | HTMLInputElement>(null);
    const refChat = useRef<null | HTMLInputElement>(null);

    const [data, setData] = useState({
        message: "",
    });

    const [isTypingActive, setTypingActive] = useState<boolean>(false);
    const [isModalActive, setModalActive] = useState<boolean>(false);
    const [extractType, setExtractType] = useState<string>("json");
    const [isExtractActive, setExtractActive] = useState<boolean>(false);
    const [isTypeSelectActive, setTypeSelectActive] = useState<boolean>(false);
    const [activeURL, setActiveURL] = useState<string>("");

    // Messages
    const [dataMessages, setDataMessages] = useState<DataMessages[]>([]);
    interface DataMessages {
        role: string;
        content: any;
        sendtime: Date;
        avatar: any;
    }

    // Set Chat Message
    const setChatMessage = (role: any, content: any, avatar:any) => {
        setDataMessages( dataMessages => [...dataMessages, {
            role : role,
            content : content,
            sendtime: new Date(),
            avatar: avatar
        }])
    };

    const extractJSON = () => {
        setExtractType("json");
        setTypeSelectActive(false);
        if (!globalStores.auth.isAuthorized) {
            setModalActive(true);
        }
    }

    const extractExcel = () => {
        setExtractType("xlsx");
        setTypeSelectActive(false);
        if (!globalStores.auth.isAuthorized) {
            setModalActive(true);
        }
    }

    const extractCSV = () => {
        setExtractType("csv");
        setTypeSelectActive(false);
        if (!globalStores.auth.isAuthorized) {
            setModalActive(true);
        }
    }

    const handleDownload = async (api: string) => {
        const downloadResponse = await requestAPI(
            api,
            "get"
        );
        if (downloadResponse.ok) {
            let blob = await downloadResponse.blob();
            let url = window.URL.createObjectURL(new Blob([blob]));
            return url.toString()
        }
    };

    const fetchExtraction = async (url: string, type: string) => {
        const extractFetch = await requestAPI(
            settings.API_ROUTES.extractFromURL,
            "post",
            {
                url: url,
                output_type: type
            }
        );
        if (extractFetch.ok) {
            const extractedData = await extractFetch.json();
            setTypingActive(false);
            const listButtons = await Promise.all(
                extractedData.result.map(async (element: Dict, index: number) => (
                    await handleDownload(
                        `${settings.API_ROUTES.download}/${extractedData.task_id}/${index.toString()}.${type}`
                    )
                ))
            )
            setChatMessage(
                "assistant", 
                ReactDOMServer.renderToString(
                    <div>
                        {extractedData.result.map((element: Dict, index: number) => (
                            <div key={index}>                                    
                                <h3>{element.summary}</h3>
                                <SyntaxHighlighter language="python" style={docco}>
                                    {JSON.stringify(element.extracted.slice(0, 3), null, 2)}
                                </SyntaxHighlighter>
                                <a 
                                    href={listButtons[index]} 
                                    download={`${extractedData.task_id}_${index.toString()}.${type}`}
                                >
                                    Donwload
                                </a>
                                <br/>
                            </div>
                        ))}
                    </div>
                ), 
                bijectionAvatar
            )
        } else {
            if(extractFetch.status == 401){
                globalStores.auth.processSignOut();
            }
            if(extractFetch.status == 403){
                setModalActive(true);
            }
        }
    }

    useEffect(() => {
        if (isExtractActive) {
            fetchExtraction(activeURL, extractType)
            setExtractActive(false);
        }
    });

    const handleSubmit = async (e: any) => {
        e.preventDefault();

        try {
            if (!data.message) {
                throw new Error("Message is required.");
            }
            if (!validHttpUrl(data.message)) {
                setData({message: ""});
                console.log(globalStores.user.profileImgUrl);
                setChatMessage(
                    "user", 
                    data.message, 
                    globalStores.user.profileImgUrl ? globalStores.user.profileImgUrl : userAvatar
                );
                setChatMessage("assistant", ["Invalid URL"], bijectionAvatar);
            } else {
                try {
                    setData({message: ""});
                    setTypingActive(true);
                    setChatMessage(
                        "user", 
                        data.message, 
                        globalStores.user.profileImgUrl ? globalStores.user.profileImgUrl : userAvatar
                    );
                    const highlightFetch = await requestAPI(
                        (
                            globalStores.auth.isAuthorized ? 
                            settings.API_ROUTES.highlightFromURL : 
                            settings.API_ROUTES.highlightFromURLNoToken
                        ),
                        "post",
                        {
                            url: data.message,
                        }
                    );
                    if (highlightFetch.ok) {
                        const highlightedHTML = await highlightFetch.json();
                        setChatMessage("assistant", highlightedHTML, bijectionAvatar);
                        setTypingActive(false);
                        setTypeSelectActive(true);
                        setActiveURL(data.message);
                        if (globalStores.auth.isAuthorized) {
                            fetchExtraction(data.message, extractType);
                        }
                    } else {
                        setTypingActive(false);
                        setChatMessage("assistant", ["failed"], bijectionAvatar);
                    }
                } catch (err: any) {
                    console.log(err.message)
                }
            }
        } catch (err: any) {
            console.log(err.message)
        }
    };

    const handleInputChange = (evt: any) => {
        const { name, value } = evt.target;
        setData({
            ...data,
            [name]: value,
        });
    };

    const modalClose = () => {
        setModalActive(false);
        if (globalStores.auth.isAuthorized) {
            setExtractActive(true);
        }
    }

    const extractClose = () => {
        if (globalStores.auth.isAuthorized) {
            setExtractActive(true);
        }
    }

    const modalSignIn = () => {
        routerNavigate(settings.INNER_ROUTES.account);
    }

    return (
        <ProgrammWrap className="app">
            <div className="content">
                <div className="content-chat">
                    <ProgramChat className="content-chat-messages" ref={refChat}>
                        {dataMessages.map((item, i) => (

                            <div key={i} className={`message ${item.role}`}>
                                <div className="message-photo">
                                    <img src={item.avatar} alt=""/>
                                </div>
                                <div className="message-content">
                                    <div className="message-date">{item.sendtime.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true })}</div>
                                    <div className="message-text" dangerouslySetInnerHTML={{__html: item.content}}>

                                    </div>
                                    {
                                        item.role === "assistant" ? (
                                            <ProgrammDropdown className="white message-dropdown">
                                                <svg width="16" height="4" viewBox="0 0 16 4" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <circle cx="8" cy="2" r="2" fill="#739EF2"/>
                                                    <circle cx="14" cy="2" r="2" fill="#739EF2"/>
                                                    <circle cx="2" cy="2" r="2" fill="#739EF2"/>
                                                </svg>
                                            </ProgrammDropdown>
                                        ) : ""
                                    }
                                </div>
                            </div>
                        ))}
                        {
                            isTypingActive ? (
                                <ProgramChatTyping>
                                    <div className="typing">
                                        <div className="typing__dot"></div>
                                        <div className="typing__dot"></div>
                                        <div className="typing__dot"></div>
                                    </div>
                                </ProgramChatTyping>
                            ) : ""
                        }
                    </ProgramChat>
                    <div className="content-chat-form">
                        <ProgramForm noValidate>
                            <ProgramInput
                                type="text"
                                name="message"
                                placeholder="Ask anything or paste the link..."
                                value={data.message}
                                autoComplete="off"
                                ref={refInputField}
                                onChange={(e) => {
                                    handleInputChange(e);
                                }}
                                required
                            ></ProgramInput>
                            <ProgramButton onClick={handleSubmit} type="submit">
                                <svg width="20" height="18" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fillRule="evenodd" clipRule="evenodd" d="M0 10.2268L16 8.72683L0 7.22683L0 0.500123C0 0.429384 0.0150103 0.35945 0.0440392 0.294941C0.157358 0.043121 0.453362 -0.0691563 0.705182 0.0441627L18.9868 8.27087C19.0982 8.32101 19.1874 8.41022 19.2375 8.52165C19.3509 8.77347 19.2386 9.06947 18.9868 9.18279L0.705182 17.4095C0.640674 17.4385 0.570739 17.4535 0.5 17.4535C0.223858 17.4535 0 17.2297 0 16.9535L0 10.2268Z" fill="#181C32" fillOpacity="0.6"/>
                                </svg>
                            </ProgramButton>
                        </ProgramForm>
                    </div>
                </div>
            </div>
            {
                (isTypeSelectActive) ? (
                    <ChatModal className={`${isTypeSelectActive ? "active" : ""}`}>
                        <div className="modal-close" onClick={extractClose}>
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M19.2807 18.2193C19.3504 18.289 19.4056 18.3717 19.4433 18.4628C19.4811 18.5538 19.5005 18.6514 19.5005 18.7499C19.5005 18.8485 19.4811 18.9461 19.4433 19.0371C19.4056 19.1281 19.3504 19.2109 19.2807 19.2806C19.211 19.3502 19.1283 19.4055 19.0372 19.4432C18.9462 19.4809 18.8486 19.5003 18.7501 19.5003C18.6515 19.5003 18.5539 19.4809 18.4629 19.4432C18.3718 19.4055 18.2891 19.3502 18.2194 19.2806L12.0001 13.0602L5.78068 19.2806C5.63995 19.4213 5.44907 19.5003 5.25005 19.5003C5.05103 19.5003 4.86016 19.4213 4.71943 19.2806C4.5787 19.1398 4.49963 18.949 4.49963 18.7499C4.49963 18.5509 4.5787 18.36 4.71943 18.2193L10.9397 11.9999L4.71943 5.78055C4.5787 5.63982 4.49963 5.44895 4.49963 5.24993C4.49963 5.05091 4.5787 4.86003 4.71943 4.7193C4.86016 4.57857 5.05103 4.49951 5.25005 4.49951C5.44907 4.49951 5.63995 4.57857 5.78068 4.7193L12.0001 10.9396L18.2194 4.7193C18.3602 4.57857 18.551 4.49951 18.7501 4.49951C18.9491 4.49951 19.1399 4.57857 19.2807 4.7193C19.4214 4.86003 19.5005 5.05091 19.5005 5.24993C19.5005 5.44895 19.4214 5.63982 19.2807 5.78055L13.0604 11.9999L19.2807 18.2193Z" fill="black"/>
                            </svg>
                        </div>
                        <div className="modal-title">Pick the format to download</div>
                        <div className="modal-buttons">
                            <ChatButton onClick={extractJSON} type="button" className="json">JSON</ChatButton>
                            <ChatButton onClick={extractExcel} type="button" className="xlsx">Excel</ChatButton>
                            <ChatButton onClick={extractCSV} type="button" className="csv">CSV</ChatButton>
                        </div>
                    </ChatModal>
                ) : ""
            } 
            {
                (isModalActive) ? (
                    <ChatModal className={`${(isModalActive) ? "active" : ""}`}>
                        <img className="modal-logo" src={logo} alt="Bijection"/>
                        <div className="modal-close" onClick={modalClose}>
                            <svg width="32" height="32" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <circle cx="25" cy="25" r="24.5" stroke="#121111"/>
                                <path d="M32.0121 17.9879L17.9879 32.0122M17.9879 17.9879L32.0121 32.0122" stroke="#121111"/>
                            </svg>
                        </div>
                        <div className="modal-title">Sign In to proceed</div>
                        {
                            (globalStores.auth.isAuthorized) ? (
                                <div>
                                    <img src={globalStores.user.profileImgUrl} alt="user image" />
                                    <h3>User Logged in</h3>
                                    <p>Name: {globalStores.user.name}</p>
                                    <p>Email Address: {globalStores.user.email}</p>
                                    <p>Credits available: {globalStores.auth.limit - globalStores.auth.requestCount}</p>
                                </div> 
                            ) : <OAuth/>
                        }
                    </ChatModal>
                ) : ""
            }
        </ProgrammWrap>
    );
});
