import React from "react";

import { SignUpForm } from "../../components";

export const SignUpApp: React.FC = () => {
    return (
        <>
            <SignUpForm />
        </>
    );
};
