import { createGlobalStyle } from "styled-components";
import { baseTheme } from "./theme";

import MignonBold from "../assets/fonts/Mignon/Mignon-Bold.woff2";
import MignonMedium from "../assets/fonts/Mignon/Mignon-Medium.woff2";
import MignonRegular from "../assets/fonts/Mignon/Mignon-Regular.woff2";

//These styles ensure that there is no margin or padding on the page, except in places where they are explicitly stated
export default createGlobalStyle`

      @font-face {
        font-display: swap;
        font-family: "Onest";
        font-style: normal;
        font-weight: 400;
        src: url(${MignonRegular}) format("woff2");
      }
    
      @font-face {
       font-display: swap;
       font-family: "Onest";
       font-style: normal;
       font-weight: 500;
       src: url(${MignonMedium}) format("woff2");
      }

      @font-face {
       font-display: swap;
       font-family: "Onest";
       font-style: normal;
       font-weight: 700;
       src: url(${MignonBold}) format("woff2");
      }
  
    *{
        margin: 0;
        padding: 0;
        box-sizing: border-box;
        font-family: "Onest", sans-serif; 
    };
    html{
        margin: 0 auto;
        line-height: 1.4;
        font-size: 14px;
          max-width: 100vw;
          overflow: hidden;
    };
    body {
        min-width: 320px;
        background-color:  ${baseTheme.colors.bgMain};
        color:  ${baseTheme.colors.grey};
    };
`;
