import React from "react";
import { useNavigate } from "react-router-dom";

import { observer } from "mobx-react-lite";
import settings from "../../common/settings";
import { MultiContext } from "../../contexts";
import { Button } from "./AuthButton.styles";

interface AuthButtonProps {
    text: string;
    link?: string;
    action?: Function;
};
export const AuthButton: React.FC<AuthButtonProps> = observer((props:AuthButtonProps) => {
    const globalStores = React.useContext(MultiContext);
    const routerNavigate = useNavigate();
    const onAuthButtonHandler = async () => {
        try {
            props.action && await props.action();
            props.link && (
                globalStores.auth.isAuthorized 
                    ? routerNavigate(settings.INNER_ROUTES.programm) 
                    : routerNavigate(props.link)
            );
        } catch (error) {
            console.log(`An action specified for this button returns an error: ${error}`);
        }
    };

    return (
        <Button onClick={onAuthButtonHandler}> {props.text} </Button>
    );
});
