import React from "react";
import ReactSelect from "react-select";

import { Link } from "react-router-dom";
import logo from "../../assets/logo.jpeg";
import settings from "../../common/settings";
import { AuthButton } from "../AuthButton";
import { AuthFormInputWrap, AuthFormWrap, AuthForma } from "./AuthForm.styles";

const options = [
    {
        value: "Segment the content on chapters",
        label: "Segment the content on chapters",
    },
    {
        value: "Generate clips from the content",
        label: "Generate clips from the content",
    },
    { value: "Summarize the meeting", label: "Summarize the meeting" },
    { value: "Other", label: "Other" },
];

interface AuthFormProps {
    page: string;
}

export const AuthForm: React.FC<AuthFormProps> = (props: AuthFormProps) => {
    const [data, setData] = React.useState({
        email: "",
        password: "",
    });

    const handleChange = (evt: any) => {
        const { name, value } = evt.target;
        setData({
            ...data,
            [name]: value,
        });
    };

    const handleSubmitSignUp = (evt: any) => {
        evt.preventDefault();

    };

    const handleSubmitSignIn = () => {
        console.log('signed in')

    };

    return (
        <AuthFormWrap>
            <AuthForma autoComplete="off">
                <img src={logo} alt="logo" />
                <h2>Bijection</h2>
                {props.page === settings.INNER_ROUTES.signIn && (
                    <p>
                        Do not have an account?
                        <Link to={settings.INNER_ROUTES.signIn} style={{ textDecoration: "none" }}>
                            <span> Register</span>
                        </Link>{" "}
                        now.
                    </p>
                )}
                <AuthFormInputWrap>
                    <input
                        type="email"
                        name="email"
                        placeholder="Email Address"
                        className="login__text login__text_type_email"
                        value={data.email}
                        onChange={(e) => {
                            handleChange(e);
                        }}
                        required
                    ></input>
                </AuthFormInputWrap>
                <AuthFormInputWrap>
                    <input
                        type="password"
                        name="password"
                        placeholder="Password"
                        value={data.password}
                        onChange={(e) => {
                            handleChange(e);
                        }}
                        required
                    ></input>
                </AuthFormInputWrap>
                {props.page === settings.INNER_ROUTES.signUp && (
                    <>
                        <AuthFormInputWrap>
                            <input
                                type="text"
                                name="full name"
                                placeholder="Full Name"
                            ></input>
                        </AuthFormInputWrap>
                        <AuthFormInputWrap>
                            <ReactSelect
                                options={options}
                                name="goal-of-use"
                                className="react-select"
                                classNamePrefix="react-select"
                                placeholder="Goal of use"
                            />
                        </AuthFormInputWrap>
                        <AuthButton text="Sign Up" action={handleSubmitSignUp}/>
                    </>
                )}
                {props.page === settings.INNER_ROUTES.signIn && (
                    <>
                        <AuthButton text="Sign In" action={handleSubmitSignIn} link={settings.INNER_ROUTES.signUp}/>
                        <a>Forgot password</a>
                    </>
                )}
            </AuthForma>
        </AuthFormWrap>
    );
};
