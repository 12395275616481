import React from "react";
import { Navigate } from "react-router-dom";

import settings from "../../common/settings";
import { MultiContext } from "../../contexts";

interface ProtectedRouteProps {
    children: React.ReactNode;
}
export const ProtectedRoute: React.FC<ProtectedRouteProps> | any = (
    props: ProtectedRouteProps
) => {
    const globalStores = React.useContext(MultiContext);

    return globalStores.auth.isAuthorized ? (
        props.children
    ) : (
        <Navigate to={settings.INNER_ROUTES.signIn} />
    );
};
