import React from "react";
import { Link, useNavigate } from "react-router-dom";
import ReactSelect from "react-select";

import { observer } from "mobx-react-lite";
import { validateEmail } from "../../common/helpers/validators";
import settings from "../../common/settings";
import { MultiContext } from "../../contexts";
import {
    AuthButton,
    AuthForm,
    AuthFormContent,
    AuthFormFooter,
    AuthFormHeader,
    AuthFormInput,
    AuthLogo,
    AuthWrap
} from "./../SignInForm/SignInForm.styles";

import logo from "../../assets/logo.svg";

const options = [
    {
        value: "Segment the content on chapters",
        label: "Segment the content on chapters",
    },
    {
        value: "Generate clips from the content",
        label: "Generate clips from the content",
    },
    { value: "Summarize the meeting", label: "Summarize the meeting" },
    { value: "Other", label: "Other" },
];

export const SignUpForm: React.FC = observer(() => {
    const globalStores = React.useContext(MultiContext);
    const [data, setData] = React.useState({
        email: "",
        password: "",
        name: "",
        goalOfUse: "",
    });

    const [error, setError] = React.useState({
        email: "",
        password: "",
        name: "",
    });

    const [generalError, setGeneralError] = React.useState("");
    const [errorMessage, setErrorMessage] = React.useState("");
    const [isWrong, setIsWrong] = React.useState(false);
    const navigate = useNavigate();

    const handleChangeInput = (evt: any) => {
        if (!evt.target) {
            setData({
                ...data,
                ["goalOfUse"]: evt.value,
            });
        } else {
            const { name, value } = evt.target;
            setData({
                ...data,
                [name]: value,
            });
        }
    };

    const handleChangeInputError = (evt: any) => {
        setIsWrong(false);
        if (evt.target) {
            if (!evt.target.validity.valid) {
                const { name, value } = evt.target;
                setError({
                    ...error,
                    [name]: evt.target.validationMessage,
                });
                setErrorMessage(evt.target.validationMessage);
            } else {
                const { name, value } = evt.target;
                setError({
                    ...error,
                    [name]: "",
                });
            }
        }
    };

    const handleSubmit = async (evt: any) => {
        evt.preventDefault();
        setGeneralError("");
        setIsWrong(true);
        try {
            if (!data.email && !data.password) {
                throw new Error("Email and password are required");
            }
            if (!data.email) {
                throw new Error("Email is required");
            }
            if (!data.password) {
                throw new Error("Password is required");
            }
            if (!validateEmail(data.email)) {
                throw new Error("Invalid Email");
            }
            const response = await globalStores.auth.processSignUp(
                data.email,
                data.password,
                data.name,
                data.goalOfUse
            );
            try {
                if (response.whatFailed === 0) {
                    navigate(settings.INNER_ROUTES.signIn);
                } else if (
                    response.whatFailed === 1 &&
                    response.statusCode == 401
                ) {
                    throw new Error(
                        "The user with such email is already registered."
                    );
                } else {
                    throw new Error(
                        "Something went wrong. Try again later or write to our email."
                    );
                }
            } catch (err: any) {
                setGeneralError(err.message);
            }
        } catch (err: any) {
            if (err.message === "Failed to fetch") {
                setGeneralError(
                    "Something went wrong. Try again later or write to our email."
                );
            } else {
                setGeneralError(err.message);
            }
        }
    };

    return (
        <AuthWrap>
            <AuthLogo>
                <img src={logo} alt="To The Point"/>
            </AuthLogo>
            <AuthForm autoComplete="off" noValidate>
                <AuthFormHeader>
                    <h1>Sign up</h1>
                </AuthFormHeader>
                <AuthFormContent>
                    <AuthFormInput
                        type="email"
                        name="email"
                        placeholder="Email Address*"
                        className="login__text login__text_type_email"
                        value={data.email}
                        onChange={(e) => {
                            handleChangeInput(e);
                            handleChangeInputError(e);
                        }}
                        required
                    ></AuthFormInput>
                    <AuthFormInput
                        type="text"
                        name="name"
                        placeholder="Name"
                        value={data.name}
                        onChange={(e) => {
                            handleChangeInput(e);
                            handleChangeInputError(e);
                        }}
                    ></AuthFormInput>
                    <AuthFormInput
                        type="password"
                        name="password"
                        placeholder="Password*"
                        value={data.password}
                        onChange={(e) => {
                            handleChangeInput(e);
                            handleChangeInputError(e);
                        }}
                        required
                    ></AuthFormInput>
                    <ReactSelect
                        options={options}
                        name="goal-of-use"
                        className="react-select"
                        classNamePrefix="react-select"
                        placeholder="Goal of use"
                        onChange={(e) => {
                            handleChangeInput(e);
                            handleChangeInputError(e);
                        }}
                    />
                    <AuthButton type="submit" onClick={handleSubmit}>
                        Sign Up
                    </AuthButton>
                </AuthFormContent>
                <AuthFormFooter>
                    <div className="footer-info">
                        Do you have an account?
                        <Link to="/" style={{ textDecoration: "none" }}>
                            <span> Log in</span>
                        </Link>
                    </div>
                    <div className="footer-error">
                        {generalError}
                    </div>
                </AuthFormFooter>
            </AuthForm>
        </AuthWrap>
    );
});
